<footer class="bg-primary h-full text-white">
    <div
        [ngClass]="[
            'container mx-auto h-full flex flex-col justify-center items-center',
            'py-8 space-y-4',
            'md:py-10 md:space-y-6',
        ]"
    >
        <div class="text-caption text-center" translate>
            LandingPageFooterText
        </div>
        <div
            [ngClass]="[
                'flex flex-wrap justify-center items-center font-medium',
                '-mx-2',
                'md:-mx-4',
            ]"
        >
            <a
                [ngClass]="['text-white no-underline', 'mx-2', 'md:mx-4']"
                [routerLink]="['/contact']"
            >
                <translate>Contact</translate>
            </a>
            <a
                [ngClass]="['text-white no-underline', 'mx-2', 'md:mx-4']"
                [routerLink]="['/imprint']"
            >
                <translate>Imprint</translate>
            </a>
            <a
                [ngClass]="['text-white no-underline', 'mx-2', 'md:mx-4']"
                [routerLink]="['/privacy']"
            >
                <translate>Privacy</translate>
            </a>
            <a
                [ngClass]="['text-white no-underline', 'mx-2', 'md:mx-4']"
                [routerLink]="['/terms-and-conditions']"
            >
                <translate>Terms of Use </translate>
            </a>
        </div>
    </div>
</footer>
