<div
    class="bg-primary h-full w-full overflow-y-auto"
    [@slideContent]="animationState"
    (@slideContent.start)="onAnimationStart($event)"
    (@slideContent.done)="onAnimationDone($event)"
>
    <div class="container mx-auto py-8 space-y-8">
        <div>
            <app-button
                buttonType="stroked-inverted"
                class="h-12"
                (buttonClick)="signUp.emit()"
            >
                <translate>Sign up for free</translate>
            </app-button>
        </div>

        <app-button (buttonClick)="signIn()" icon="user" iconPosition="left">
            <translate>Login</translate>
        </app-button>
    </div>

    <div class="border-0 border-b-2 border-primary-dark border-solid"></div>

    <div class="container mx-auto py-8 space-y-8">
        <app-landing-page-language-selector></app-landing-page-language-selector>
    </div>
</div>
