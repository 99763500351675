import {
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
} from '@angular/common/http'
import { Injectable, OnDestroy } from '@angular/core'
import { AuthzService } from '@auth-util-lib/authz.service'
import {
    selectGroupsFromBackendLoading,
    selectSelectedGroupIds,
} from '@group-management-lib/redux/group-management.selectors'
import { GroupManagementState } from '@group-management-lib/redux/group-management.state'
import { select, Store } from '@ngrx/store'
import {
    BehaviorSubject,
    combineLatestWith,
    Observable,
    of,
    ReplaySubject,
    Subscription,
} from 'rxjs'
import {
    filter,
    map,
    pairwise,
    startWith,
    switchMap,
    take,
} from 'rxjs/operators'
import { ApiService } from '@env-lib/api/api.service'
import { AuthnService } from '@auth-util-lib/authn.service'
import { hasValue } from '@util-lib/hasValue'
import { JwtHelperService } from '@auth0/angular-jwt'
import { splitToArray } from '@util-lib/array.utils'

const traigoApiCallMatcher = /https:\/\/.*\.(dev|int|prod)\.traigo\.com/
const attachAllGroupIdsMatchers = [
    // https://fleet.int.traigo.com/fleet/wagons/285688
    // https://fleet.int.traigo.com/fleet/wagons/285688/status
    // https://fleet.int.traigo.com/fleet/wagons/285688/maintenances?from=2023-12-31T23:00:00.000Z&to=2024-12-31T22:59:59.999Z
    // https://fleet.int.traigo.com/fleet/wagons/285688/mileage?year=2024
    /https:\/\/fleet\.(dev|int|prod)\.traigo\.com\/fleet\/wagons\/.+/,
    // https://pathfinder.int.traigo.com/assets/285688/position
    /https:\/\/pathfinder\.(dev|int|prod)\.traigo\.com\/assets\/.+\/.+/,
    // https://sensor.int.traigo.com/sensor/wagon
    /https:\/\/sensor\.(dev|int|prod)\.traigo\.com\/sensor\/wagon/,
]

const isNoTaigoApiCall = (request: HttpRequest<unknown>) =>
    !request.url.match(traigoApiCallMatcher)

const shouldReceiveAllGroupIds = (request: HttpRequest<unknown>) =>
    attachAllGroupIdsMatchers.some((matcher) => request.url.match(matcher))

@Injectable()
export class TokenAndGroupIdsInterceptor implements HttpInterceptor, OnDestroy {
    private readonly subscriptions = new Subscription()
    private readonly selectedGroupIds$ = new ReplaySubject<string | null>(1)
    private readonly groupsInitialized$ = new ReplaySubject<boolean>(1)
    private readonly isAuthorized$ = new BehaviorSubject<boolean>(false)
    private readonly currentToken$ = new ReplaySubject<string | null>(1)

    private readonly jwtHelper = new JwtHelperService()
    readonly tokenEndpoint: string

    constructor(
        store: Store<GroupManagementState>,
        authz: AuthzService,
        authn: AuthnService,
        apiService: ApiService
    ) {
        this.tokenEndpoint = apiService.auth.tokenEndpoint

        this.subscriptions.add(
            store
                .pipe(select(selectSelectedGroupIds))
                .subscribe(this.selectedGroupIds$)
        )

        this.subscriptions.add(
            authz.authorizedForTraigo$.subscribe(this.isAuthorized$)
        )

        this.subscriptions.add(
            authn.tokenChanges().subscribe(this.currentToken$)
        )

        // groupsInitialized$ is a subject which emits true, once group management is fully initialized.
        // This is the case once groupsFromBackendLoading in the store switches from false to true.
        this.subscriptions.add(
            store
                .pipe(
                    select(selectGroupsFromBackendLoading),
                    startWith(false),
                    pairwise(),
                    map(([wasLoading, isLoading]) => wasLoading && !isLoading),
                    filter((stoppedLoading) => stoppedLoading),
                    take(1)
                )
                .subscribe(this.groupsInitialized$)
        )
    }

    intercept(
        request: HttpRequest<unknown>,
        next: HttpHandler
    ): Observable<HttpEvent<unknown>> {
        return this.decorateRequest(request).pipe(
            switchMap((request) => next.handle(request))
        )
    }

    private decorateRequest(
        request: HttpRequest<unknown>
    ): Observable<HttpRequest<unknown>> {
        if (isNoTaigoApiCall(request)) {
            return of(request)
        }
        const suppressGroupIds =
            !this.isAuthorized$.getValue() || request.url.includes('auth')
        const shouldAttachAllGroupIds = shouldReceiveAllGroupIds(request)
        if (suppressGroupIds) {
            return this.decorateRequestOnlyWithToken(request)
        } else if (shouldAttachAllGroupIds) {
            return this.decorateRequestWithTokenAndAllGroupIds(request)
        } else {
            return this.decorateRequestWithTokenAndPermittedSelectedGroupIds(
                request
            )
        }
    }

    private decorateHeaderWithTokenAndGroupIds(
        request: HttpRequest<unknown>,
        token: string,
        groupIds: string
    ): HttpRequest<unknown> {
        // Add cognito jwt token to request header
        const tokenRequest = this.addToken(token, request)
        // Add group ids as x header to request
        return this.addGroupIds(groupIds, tokenRequest)
    }

    private decorateRequestOnlyWithToken(
        request: HttpRequest<unknown>
    ): Observable<HttpRequest<unknown>> {
        return this.currentToken$.pipe(
            filter(hasValue),
            // this take(1) is important, otherwise observable won't emit
            take(1),
            // Add cognito jwt token to request header
            map((token) => this.addToken(token, request))
        )
    }

    private decorateRequestWithTokenAndAllGroupIds(
        request: HttpRequest<unknown>
    ): Observable<HttpRequest<unknown>> {
        return this.currentToken$.pipe(
            filter(hasValue),
            // this take(1) is important, otherwise observable won't emit
            take(1),
            // Add cognito jwt token to request header
            map((token) => {
                const allGroupIds = this.extractAllGroupIds(token)
                return this.decorateHeaderWithTokenAndGroupIds(
                    request,
                    token,
                    allGroupIds
                )
            })
        )
    }

    private decorateRequestWithTokenAndPermittedSelectedGroupIds(
        request: HttpRequest<unknown>
    ): Observable<HttpRequest<unknown>> {
        return this.groupsInitialized$.pipe(
            // this take(1) is important, otherwise observable won't emit
            take(1),
            // consumes changes until the non-nullable value signals valid group ids
            combineLatestWith(
                this.selectedGroupIds$.pipe(filter(hasValue), take(1))
            ),
            // get the latest token AFTER valid group ids are received - this makes sure that the groups in the token and in the header are in sync
            combineLatestWith(
                this.currentToken$.pipe(filter(hasValue), take(1))
            ),
            map(([[_, selectedGroupIds], token]) => {
                const permittedSelectedGroupIds =
                    this.removeNotPermittedGroupIds(selectedGroupIds, token)
                return this.decorateHeaderWithTokenAndGroupIds(
                    request,
                    token,
                    permittedSelectedGroupIds
                )
            })
        )
    }

    private extractAllGroupIds(token: string) {
        return this.jwtHelper.decodeToken(token)?.group_ids ?? ''
    }

    private removeNotPermittedGroupIds(groupIds: string, token: string) {
        const tokenGroupIds = splitToArray(
            this.jwtHelper.decodeToken(token)?.group_ids
        )
        return splitToArray(groupIds)
            .filter((groupId) => tokenGroupIds.includes(groupId))
            .join(',')
    }

    private addToken(token: string, request: HttpRequest<unknown>) {
        const isUrlBlacklisted = this.tokenEndpoint === request.url
        let headers = request.headers
        if (!headers.has('SkipAuthInterceptor') && !isUrlBlacklisted) {
            headers = headers.set('Authorization', `Bearer ${token}`)
        }
        headers = headers.delete('SkipAuthInterceptor')
        return request.clone({ headers })
    }

    private addGroupIds(groupIds: string, request: HttpRequest<unknown>) {
        return request.clone({
            headers: request.headers.set('X-Groups', groupIds),
        })
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe()
    }
}
