import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { MatIconModule } from '@angular/material/icon'
import { RouterModule } from '@angular/router'
import { TranslationModule } from '@localization-lib/language/translation.module'
import { BreadcrumbComponent } from './breadcrumb.component'

@NgModule({
    declarations: [BreadcrumbComponent],
    exports: [BreadcrumbComponent],
    imports: [CommonModule, MatIconModule, RouterModule, TranslationModule],
})
export class BreadcrumbModule {}
