import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { MatIconModule } from '@angular/material/icon'
import { TranslationModule } from '@localization-lib/language/translation.module'
import { ContactComponent } from '@platform-lib/legal/contact/contact.component'
import { ServiceDeskStatusModule } from '@platform-lib/legal/contact/service-desk-status/service-desk-status.module'
import { BackToTopModule } from '@shared-ui-lib/back-to-top/back-to-top.module'
import { AppLottieModule } from '@shared-ui-lib/lottie/lottie.module'
import { PageModule } from '@shared-ui-lib/page/page.module'

@NgModule({
    declarations: [ContactComponent],
    imports: [
        AppLottieModule,
        BackToTopModule,
        CommonModule,
        MatIconModule,
        PageModule,
        ServiceDeskStatusModule,
        TranslationModule,
    ],
})
export class ContactModule {}
