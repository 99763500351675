import { Component } from '@angular/core'
import { ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router'
import { Breadcrumb } from './breadcrumb.model'
import { replaceSpacesWithUnderscores } from '@util-lib/spacesToUnderscores'

@Component({
    selector: 'app-breadcrumb',
    styleUrls: ['./breadcrumb.component.scss'],
    templateUrl: './breadcrumb.component.html',
})
export class BreadcrumbComponent {
    breadcrumb: Breadcrumb[] = []

    constructor(private activatedRoute: ActivatedRoute) {
        this.pushActiveBreadcrumb(this.activatedRoute.snapshot)
    }

    public dataQaNameForBreadcrumb(breadcrumb: Breadcrumb) {
        const underscoredBreadcrumbName = replaceSpacesWithUnderscores(
            breadcrumb.name.toLowerCase()
        )
        return `page_breadcrumb_${underscoredBreadcrumbName}`
    }

    private pushActiveBreadcrumb(activatedRoute: ActivatedRouteSnapshot) {
        if (activatedRoute.parent) {
            this.pushActiveBreadcrumb(activatedRoute.parent)
            if (activatedRoute.data.breadcrumb) {
                const formatName = this.formatName(
                    activatedRoute.data.breadcrumb.name,
                    activatedRoute
                )
                const formatRoute = this.formatRoute(activatedRoute)

                if (!this.breadcrumb.find((bread) => bread.name === formatName))
                    this.breadcrumb.push({
                        name: formatName,
                        route: formatRoute,
                    })
            }
        }
    }

    private formatName(name: string, snapshot: ActivatedRouteSnapshot) {
        if (snapshot.data.breadcrumb.replaceFromQueryParams) {
            return name.replace(
                '$1',
                snapshot.queryParams[
                    snapshot.data.breadcrumb.replaceFromQueryParams
                ]
            )
        } else {
            return name
        }
    }

    private formatRoute(activatedRoute: ActivatedRouteSnapshot): string {
        if (activatedRoute?.data?.breadcrumb?.url) {
            let url = activatedRoute.data.breadcrumb.url as string

            const placeholderInUrl = (
                activatedRoute.data.breadcrumb.url as string
            )
                .match(/\/:[\w]+/)
                ?.map((entry) => entry.substring(2))

            if (placeholderInUrl && placeholderInUrl?.length > 0) {
                placeholderInUrl.forEach((placeholder) => {
                    const paramValue =
                        activatedRoute.params?.[placeholder] || placeholder
                    url = url.replace(`:${placeholder}`, paramValue)
                })
            }

            return url
        }

        return ''
    }
}
