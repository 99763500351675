<div class="header-container mat-typography" [ngClass]="headerClass">
    <ng-content select="[header]"></ng-content>
</div>

<ng-container>
    <app-banner-handler> </app-banner-handler>
</ng-container>

<div
    class="content-wrapper mat-typography"
    [style.backgroundColor]="customBackgroundColor || undefined"
    [class.image-background]="useBackgroundImage"
>
    <ng-content select="[content]"></ng-content>
</div>

<div class="footer-container mat-typography">
    <ng-content select="[footer]"></ng-content>
</div>
