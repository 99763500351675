import { AnimationEvent } from '@angular/animations'
import { Component, EventEmitter } from '@angular/core'
import { Router } from '@angular/router'
import { OverlayAnimatedContent } from '@shared-ui-lib/overlay/overlay-animated-content'
import { sidebarAnimationMetaData } from '@shared-util-lib/animations/sidebar-animation-meta-data'

@Component({
    selector: 'app-mobile-menu',
    templateUrl: './landing-page-mobile-menu.component.html',
    styleUrls: ['./landing-page-mobile-menu.component.scss'],
    animations: sidebarAnimationMetaData,
})
export class LandingPageMobileMenuComponent extends OverlayAnimatedContent {
    animationState: 'void' | 'enter' | 'leave' = 'enter'
    animationStateChanged = new EventEmitter<AnimationEvent>()
    signUp = new EventEmitter<void>()

    constructor(private router: Router) {
        super()
    }

    signIn() {
        this.router.navigate(['/dashboard'])
    }
}
