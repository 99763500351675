<div
    class="top-bar-wrapper bg-primary-dark h-16 md:h-32 flex flex-col justify-center z-50"
    [class.scrolled]="isScrolled"
    [class.isScrolledToSticky]="isScrolledToSticky"
>
    <div class="container h-12 mx-auto py-2">
        <div [class]="['flex', 'justify-between', 'items-center', 'h-full']">
            <div class="flex items-center">
                <app-button
                    class="mobile-menu-toggle mr-3 md:hidden"
                    (buttonClick)="toggleMobileMenu()"
                    [icon]="mobileMenuOpen ? 'close' : 'menu'"
                    iconButton
                    buttonType="basic-inverted"
                >
                </app-button>
                <a [routerLink]="['/']" class="flex">
                    <img
                        src="assets/images/traigo-logo.svg"
                        class="logo h-10 md:h-12"
                    />
                </a>
            </div>
            <div class="flex items-center">
                <app-landing-page-language-selector
                    class="mr-8 hidden md:block"
                ></app-landing-page-language-selector>

                <app-button-group
                    class="button-group"
                    [class.out]="mobileMenuOpen"
                >
                    <div class="hidden md:block">
                        <app-button
                            (click)="goToRegister()"
                            buttonType="stroked-inverted"
                            class="h-12 stroked-inverted-button"
                        >
                            <translate>Sign up for free</translate>
                        </app-button>
                    </div>

                    <app-button
                        (buttonClick)="signIn()"
                        icon="user"
                        iconPosition="left"
                    >
                        <translate>Login</translate>
                    </app-button>
                </app-button-group>
            </div>
        </div>
    </div>
</div>

<div
    class="hidden mobile-menu fixed h-screen w-screen inset-0 bg-primary z-10"
></div>
