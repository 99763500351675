import { Component } from '@angular/core'
import { LanguageService } from '@localization-lib/language/language.service'
import { Language } from '@localization-lib/language/models/Language'

@Component({
    selector: 'app-landing-page-language-selector',
    templateUrl: './landing-page-language-selector.component.html',
})
export class LandingPageLanguageSelectorComponent {
    Language = Language

    constructor(private languageService: LanguageService) {}

    selectLanguage(lang: Language) {
        this.languageService.setLanguage(lang, true)
    }
}
