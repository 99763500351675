<ng-container cdkOverlayOrigin>
    <div
        (click)="handleHeaderClick()"
        [ngClass]="[
            'text-body-3 xl:w-56 p-2',
            'flex items-center',
            'rounded border-solid border-2 border-primary',
            'hover:bg-primary cursor-pointer',
        ]"
        [class.inactive]="!headerActivated"
        [matTooltip]="tooltipText$ | async"
        [class.pointer-events-none]="loading$ | async"
    >
        <mat-icon
            svgIcon="group-objects"
            class="text-white mr-2 min-w-6"
        ></mat-icon>

        <ng-container *ngIf="loading$ | async; else error">
            <div
                class="flex-auto text-ellipsis overflow-hidden hidden xl:block"
            >
                {{ 'Loading...' | translate }}
            </div>
            <app-loading-indicator
                small
                class="text-white"
            ></app-loading-indicator>
        </ng-container>

        <ng-template #error>
            <ng-container *ngIf="error$ | async; else groupNames">
                <div
                    class="flex-auto text-ellipsis overflow-hidden hidden xl:block"
                >
                    {{ 'Loading failed' | translate }}
                </div>
                <mat-icon
                    svgIcon="drop-down"
                    [@flipIcon]="isOverlayOpen"
                    class="text-white basis-6"
                ></mat-icon>
            </ng-container>
        </ng-template>

        <ng-template #groupNames>
            <div
                class="flex-auto text-ellipsis overflow-hidden hidden xl:block"
            >
                {{ groupNames$ | async }}
            </div>
            <mat-icon
                svgIcon="drop-down"
                [@flipIcon]="isOverlayOpen"
                class="text-white basis-6 min-w-6"
            ></mat-icon>
        </ng-template>
    </div>
</ng-container>

<ng-template
    cdkConnectedOverlay
    cdkConnectedOverlayGrowAfterOpen
    [cdkConnectedOverlayOrigin]="overlayOrigin"
    [cdkConnectedOverlayOpen]="isOverlayOpen"
    [cdkConnectedOverlayPositions]="overlayPositions"
>
    <div #overlayDropdown class="contents">
        <app-group-management-dropdown
            @overlayTrigger
            (emitCloseDialog)="toggleOverlay()"
        ></app-group-management-dropdown>
    </div>
</ng-template>
