import { animate, state, style, transition, trigger } from '@angular/animations'

export const sidebarAnimationMetaData = [
    trigger('slideContent', [
        state(
            'void',
            style({
                transform: 'translateX(-100%)',
            })
        ),
        state('enter', style({ transform: 'none' })),
        state('leave', style({ transform: 'translateX(-100%)' })),
        transition('* => *', animate('400ms cubic-bezier(0.25, 0.8, 0.25, 1)')),
    ]),
]
