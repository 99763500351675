import {
    AnalyticsCategory,
    AnalyticsGroupManagementAction,
} from '@analytics-lib/analytics.model'
import { Component, OnDestroy } from '@angular/core'
import { MatDialogRef } from '@angular/material/dialog'
import { LanguageService } from '@localization-lib/language/language.service'
import { Language } from '@localization-lib/language/models/Language'
import { BehaviorSubject, Subscription } from 'rxjs'
import { GroupManagementService } from '@group-management-lib/group-management.service'

@Component({
    selector: 'app-group-management-onboarding-dialog',
    styleUrls: ['./group-management-onboarding-dialog.component.scss'],
    templateUrl: './group-management-onboarding-dialog.component.html',
})
export class GroupManagementOnboardingDialogComponent implements OnDestroy {
    AnalyticsCategory = AnalyticsCategory
    AnalyticsGroupManagementAction = AnalyticsGroupManagementAction

    readonly gifName$ = new BehaviorSubject<string>('')
    readonly isAdmin$ = this.groupManagementService.isAdmin$

    private subscriptions = new Subscription()

    constructor(
        private dialogRef: MatDialogRef<GroupManagementOnboardingDialogComponent>,
        private languageService: LanguageService,
        private groupManagementService: GroupManagementService
    ) {
        this.subscriptions.add(
            this.languageService.selectedLanguage$.subscribe((lang) => {
                if (lang === Language.DE) {
                    this.gifName$.next('onboarding_DE.gif')
                } else {
                    this.gifName$.next('onboarding_EN.gif')
                }
            })
        )
    }

    closeDialog() {
        this.dialogRef.close(true)
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe()
    }
}
