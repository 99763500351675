<div class="text-smallheading pb-4 flex justify-between">
    <ng-container *ngIf="title; else titleContent">
        <div>{{ title | translate }}</div>
    </ng-container>
    <ng-template #titleContent>
        <ng-content select="[title]"></ng-content>
    </ng-template>

    <ng-container *ngIf="_showCloseButton">
        <app-button
            icon="close"
            iconButton
            buttonType="ghost"
            (buttonClick)="onCloseClick()"
        ></app-button>
    </ng-container>
</div>

<div class="text-body-1 pb-6">
    <ng-container *ngIf="bodyText; else bodyContent">
        <div>{{ bodyText | translate }}</div>
    </ng-container>
    <ng-template #bodyContent>
        <ng-content select="[body]"></ng-content>
    </ng-template>
</div>

<ng-container *ngIf="!_hideActions">
    <ng-container *ngIf="actionButtonText; else actionContent">
        <app-button-group alignment="right">
            <app-button
                buttonType="basic-primary"
                (buttonClick)="onCloseClick()"
                ><translate>Cancel</translate></app-button
            >
            <app-button
                buttonType="flat-primary"
                (buttonClick)="onActionButtonClick()"
                ><div>{{ actionButtonText | translate }}</div></app-button
            >
        </app-button-group>
    </ng-container>
    <ng-template #actionContent>
        <ng-content select="[actions]"></ng-content>
    </ng-template>
</ng-container>
