<div class="breadcrumb" data-qa="page_breadcrumb">
    <span
        routerLink="/"
        translate
        class="breadcrumb-link"
        data-qa="page_breadcrumb_overview"
    >
        Overview
    </span>
    <span
        *ngFor="let bread of breadcrumb; last as isLast"
        [ngClass]="{ 'breadcrumb-last': isLast }"
        [attr.data-qa]="dataQaNameForBreadcrumb(bread)"
    >
        <mat-icon
            svgIcon="chevron-right"
            aria-hidden="true"
            class="breadcrumb__chevron"
        ></mat-icon>
        <span
            *ngIf="!isLast"
            routerLink="{{ bread.route }}"
            class="breadcrumb-link"
        >
            {{ bread.name | translate }}
        </span>
        <span *ngIf="isLast"> {{ bread.name | translate }} </span>
    </span>
</div>
