<div mat-dialog-content class="flex flex-col items-center overflow-hidden">
    <app-button
        class="close-button"
        buttonType="ghost"
        iconButton
        icon="close"
        (buttonClick)="closeDialog()"
    ></app-button>
    <div *ngIf="gifName$ | async as filename" class="pb-4 pt-4">
        <img
            [src]="'/assets/animations/group-management/' + filename"
            class="onboarding-gif self-center"
            alt="Onboarding"
        />
    </div>
    <span class="text-subtitle-1 pb-2 text-accent-extra-dark" translate>
        Welcome to Group Management
    </span>
    <ng-container *ngIf="isAdmin$ | async; else normalUserPopupBody">
        <span
            class="text-body-1 w-[636px] text-center text-accent-dark"
            translate
        >
            GroupManagementOnboardingPopupBodyTextAdmin
        </span>
    </ng-container>
    <ng-template #normalUserPopupBody>
        <span
            class="text-body-1 w-[636px] text-center text-accent-dark"
            translate
        >
            GroupManagementOnboardingPopupBodyTextNormalUser
        </span>
    </ng-template>
</div>
<div mat-dialog-actions align="center" class="pt-6">
    <app-button
        buttonType="flat-primary"
        [breakLines]="true"
        (buttonClick)="closeDialog()"
        trackClick
        [trackCategory]="AnalyticsCategory.GroupManagement"
        [trackAction]="
            AnalyticsGroupManagementAction.OnboardingPopupButtonClicked
        "
    >
        <translate>Got it!</translate>
    </app-button>
</div>
