import { Injectable, OnDestroy } from '@angular/core'
import { objectKeys } from '@util-lib/objectKeys'
import { BehaviorSubject, Observable, Subscription, fromEvent } from 'rxjs'
import {
    debounceTime,
    distinctUntilChanged,
    map,
    shareReplay,
} from 'rxjs/operators'

// TODO: Use when TypeScript 4.1 is supported with Testcafe
// import resolveConfig from 'tailwindcss/resolveConfig'
// const tailwindConfig = require('../../../../tailwind.config.js')
// const screens = resolveConfig(tailwindConfig).theme.screens

const screens = {
    '2xl': '1536px',
    lg: '1024px',
    md: '768px',
    sm: '640px',
    xl: '1280px',
}

type MediaMatchers = {
    [key in keyof typeof screens]: MediaQueryList
}

@Injectable({
    providedIn: 'root',
})
export class BreakpointService implements OnDestroy {
    private subscriptions = new Subscription()

    private resize$ = new BehaviorSubject<void>(void 0)

    private mediaMatchers: MediaMatchers = objectKeys(screens).reduce(
        (mediaMatcher, breakpoint) => ({
            ...mediaMatcher,
            [breakpoint]: window.matchMedia(
                `(min-width: ${screens[breakpoint]})`
            ),
        }),
        {} as MediaMatchers
    )

    private observables: {
        [key in keyof typeof screens]?: Observable<boolean>
    } = {}

    private handleWindowResize = (event: unknown) => this.resize$.next()

    constructor() {
        this.subscriptions.add(
            fromEvent(window, 'resize')
                .pipe(debounceTime(100))
                .subscribe(this.handleWindowResize)
        )
        this.subscriptions.add(
            fromEvent(window, 'orientationchange').subscribe(
                this.handleWindowResize
            )
        )
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe()
    }

    breakpoint$(bp: keyof typeof screens) {
        if (!this.observables[bp]) {
            this.observables[bp] = this.resize$.pipe(
                map(() => !!this.mediaMatchers[bp].matches),
                distinctUntilChanged(),
                shareReplay(1)
            )
        }
        return this.observables[bp] as Observable<boolean>
    }
}
