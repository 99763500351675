import { AnalyticsModule } from '@analytics-lib/analytics.module'
import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { MatDialogModule } from '@angular/material/dialog'
import { TranslationModule } from '@localization-lib/language/translation.module'
import { ButtonModule } from '@shared-ui-lib/button/button.module'
import { GroupManagementOnboardingDialogComponent } from './group-management-onboarding-dialog.component'

@NgModule({
    declarations: [GroupManagementOnboardingDialogComponent],
    exports: [GroupManagementOnboardingDialogComponent],
    imports: [
        AnalyticsModule,
        ButtonModule,
        CommonModule,
        MatDialogModule,
        TranslationModule,
    ],
})
export class GroupManagementOnboardingDialogModule {}
