import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { ExpandableComponent } from './expandable.component'

@NgModule({
    declarations: [ExpandableComponent],
    exports: [ExpandableComponent],
    imports: [CommonModule],
})
export class ExpandableModule {}
